@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400&display=swap');

html, body, #root {
    height: 100%;
}

.main {
    height: 100%;
    display: flex;
    flex-direction: column;
}

body {
    background-color: #2a4a5c;
    color: aliceblue;
}

h1 {
    font-size: 300%;
    font-family: 'Josefin Sans', sans-serif;
}

a {
    color: #4aabff;
    text-decoration: none;

    &:hover {
        color: #4687bb;
    }
}

.introCard {
    margin: 5% 5% 5% 5%;
}

.navLink {
    color: aliceblue;
}

.navLink:hover {
    color: #5fb4ff;;
}

.sidebar {
    width: fit-content;
    padding: 1%;
    border-radius: 5%;
    margin-right: 50px;
    margin-left: 10px;
    margin-bottom: 50px;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
         box-shadow: inset 0px 0px 5px #14232c;
}

.listbody {
    .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    img {
        border-radius: 50%;
        width: 50%;  
        transition: 0.3s;

        &:hover{
            width: 60%;  
            cursor: pointer;
        }
    }
    #titles {
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
    }
    .imgClass:hover img:not(:hover) {
        filter: blur(5px);
    }
    .desc {
        visibility: hidden;
        opacity: 0;
        color: white;
        transition: 0.3s;
    }
    height: 40vh; 
}

.content {

    p {
        letter-spacing: 1px;
        line-height: 1.8;
        padding-top: 1rem;
    }

    margin: 5% 17% 0% 17%;
}

code {
    white-space: pre-wrap;
    color: floralwhite;
}


.inline-block {
    display: inline-block;
}

.blogentry {
    margin: 0% 10% 0% 5%;
    border-top: 1px solid;
    border-bottom: 1px solid;
    a {
        display:block;
        padding: 3% 0% 3% 0%;
    }
    img {
        border-radius: 50%;
        width: 15vh;
        height: 15vh;
        object-fit: cover;
    }
}

.resume {
    h3 {
        font-weight: bold;
    }

    ul {
        list-style-type: none;
      }
      ul li:before {
        content: '\2014';
        position: absolute;
        margin-left: -35px;
      }

    margin: 5% 17% 0% 17%;
}

@media(max-width: 900px) {
    .content {
        margin: 5% 15% 0% 15%;
    }
}
@media(max-width: 480px) {
    .content {
        margin: 5% 10% 0% 10%;
    }
}
@media(max-width: 320px) {
    .content {
        margin: 5% 5% 0% 5%;
    }
}


.footer {
    padding: 2% 2% 10vh 2%;
    height: 10vh;
    width: 100%;
    background-color: #2B2A33;
}

